import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GuardService } from './guard.service';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private _guardService: GuardService,
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree
    | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
            return this._guardService.checkAccessToken()
    }

    canActivate(): Observable<boolean> | boolean | Promise<boolean> {
        return this._guardService.checkAccessToken()
    }
}