import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AppService } from './app.service';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class GuardService {
    constructor(
        private _cookieService: CookieService,
        private _router: Router,
        private _appService: AppService,
        private _messageService: MessageService,
    ) {
    }

    public checkAccessToken() {
        const accessToken = this._cookieService.get('Company-Token')
        if (accessToken) {
            return true
        } else {

            this._router.navigate(['auth']);
            return false
        }
    }

    public login(info: {}) {
        this._appService.postData('auth/sign-in', info).subscribe((data: any) => {
            if(data){
                const tokens = data?.token
                const expireDate = new Date();
                expireDate.setDate(expireDate.getDate() + 1);
                this._cookieService.put('Company-Token', tokens, { 'expires': expireDate, 'secure': true, 'storeUnencoded': true });
                this._router.navigate(['main']);
            }
           
        }, (err: HttpErrorResponse) => {
            console.log(err);
            if (err && err.status === 401) {
                this._messageService.add({ severity: 'error', summary: '', detail: `Սխալ տվյալներ` });
                this._cookieService.removeAll()
                setTimeout(() => {
                    this._messageService.clear()
                }, 3000);

            }
        })
        // 
    }
}
