import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: HttpClient) {}

  public postData(apiUrl: string, body: {}): Observable<any[]> {
    // Implement your logic to fetch data from the server
   
    return this.http.post<any[]>(apiUrl, body);
  }

  public getData(apiUrl: string): Observable<any[]> {
    // Implement your logic to fetch data from the server
   
    return this.http.get<any[]>(apiUrl);
  }

}
